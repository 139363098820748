import React, { useState } from 'react';
import VideoPlayer from './VideoPlayer';
import './App.css';

function App() {
  const [url, setUrl] = useState('');
  const [submittedUrl, setSubmittedUrl] = useState('');

  const handleInputChange = (event) => {
    setUrl(event.target.value);
  };

  const handleSubmit = () => {
    setSubmittedUrl(url);
  };

  const previewImage = 'https://scontent.fjsr11-1.fna.fbcdn.net/v/t1.15752-9/457796721_817940610324608_5945579723304703212_n.png?_nc_cat=110&ccb=1-7&_nc_sid=9f807c&_nc_eui2=AeFaKkCgEPLceYWA1HMTDvgaH-nSvkoWiwYf6dK-ShaLBiZrLSHpFkCHsEoezakWFzQDACpUvyHJ_40o4Rr7etkS&_nc_ohc=3Kh-PJqGBj4Q7kNvgEOaycf&_nc_ht=scontent.fjsr11-1.fna&oh=03_Q7cD1QFQwei-ERodSc0sKdC7jD0qDLGE9C3klpmst0-V3jfF7A&oe=6702B0DB'; // Example thumbnail URL

  return (
    <div className="app">
      <h1>IPTV Player</h1>
      <input
        type="text"
        placeholder="Enter IPTV URL"
        value={url}
        onChange={handleInputChange}
      />
      <button onClick={handleSubmit} className="play-button">
        Play
      </button>
      {!submittedUrl && (
        <div className="thumbnail-container">
          <img src={previewImage} alt="Thumbnail" className="thumbnail-image" />
        </div>
      )}
      {submittedUrl && <VideoPlayer src={submittedUrl} />}
    </div>
  );
}

export default App;
