import React, { useRef, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoPlayer.css';

function VideoPlayer({ src }) {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (videoRef.current && !playerRef.current) {
        playerRef.current = videojs(videoRef.current, {
          autoplay: true,
          controls: true,
          fluid: true,
          sources: [{
            src: src,
            type: 'application/x-mpegURL'
          }]
        });
      }
    }, 0); // Small delay to ensure the DOM is ready

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      clearTimeout(timeoutId);
    };
  }, [src]);

  return (
    <div className="video-container">
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-default-skin vjs-big-play-centered" />
      </div>
    </div>
  );
}

export default VideoPlayer;
